<template>
    <div class="PickUpStaff">
        <el-dialog
            title="查询员工信息"
            :visible.sync="dialogFormVisible"
            width="55%"
            top="60px"
            :close-on-click-modal="false"
            :append-to-body="true"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-form
                    @submit.native.prevent
                    :inline="true"
                    style="text-align: center"
                    v-model="form"
                    size="small"
                    label-width="80px"
                >
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="员工类型">
                                <el-select v-model="form.staffType">
                                    <el-option label="请选择" value="" />
                                    <el-option label="管理人员" value="0" />
                                    <el-option label="业务人员" value="1" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="关键字">
                                <el-input placeholder="姓名/手机号" v-model="form.search" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" :offset="2">
                            <el-form-item>
                                <el-button type="primary" @click="searchHandleQuery">查询</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>

            <el-card shadow="never" style="margin-top: 8px">
                <el-table
                    ref="pickUpStaff"
                    border
                    stripe
                    style="width: 100%"
                    :data="tableData"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="520"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" />
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left">
                        <!--                    type='selection'复选框 -->
                    </el-table-column>
                    <el-table-column label="姓名" width="160" prop="realName" />
                    <el-table-column label="手机号" width="120" prop="mobile" />
                    <el-table-column label="性别" width="80" prop="sex">
                        <template slot-scope="scope">
                            <span>{{ scope.row.sex | sex }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="年龄" width="80" prop="age" />
                    <el-table-column label="员工类型" width="120" prop="type">
                        <template slot-scope="scope">
                            <span>{{ scope.row.type | type }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="绑定角色" min-width="240" prop="roles">
                        <template slot-scope="scope">
                            <span>{{ scope.row.roles | roles }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="form.needCheckFriendsParentStaffFlag"
                        label="是否为C中的分销员"
                        width="80"
                        prop="isFriendsParentStaff"
                    >
                        <template slot-scope="scope">
                            <span>{{ scope.row.isFriendsParentStaff | isFriendsParentStaff }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    :page-sizes="page.pageSizes"
                    :page-size="form.limit"
                    :layout="page.PageStyle"
                    :total="page.total"
                    :current-page="form.page"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevClick"
                    @next-click="handleNextClick"
                />
            </el-card>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="ok" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from '../../js/Util.js';

export default {
    name: 'PickUpStaff',
    data() {
        return {
            dialogFormVisible: false,
            selectionChangeType: 'more',
            needCheckFriendsParentStaffFlag: false,
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: true,
            form: {
                deptType: '',
                deptCode: '',
                ignoreStaffCodes: '',
                search: '',
                staffType: '',
                page: 1,
                limit: Util.Limit,
            },
            selectData: [],
            tableData: [],
            url: {
                page: '/system/staff/queryExtend',
            },
        };
    },
    methods: {
        handleSelectionChange(val) {
            this.selectData = val;
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            // eslint-disable-next-line no-unused-vars
            const { page, limit, ignoreStaffCodes, ...param } = this.form;
            const dd = this.form.ignoreStaffCodes.split(',');
            param.ignoreStaffCodes = dd;
            this.$http
                .post(`${this.url.page}?page=${this.form.page}&limit=${this.form.limit}`, param, {})
                .then((re) => {
                    this.tableData = re.data.data;
                    this.page.total = re.data.count;
                })
                .catch(() => {});
        },
        show(extendParams) {
            this.form = Object.assign(this.form, extendParams);
            this.dialogFormVisible = true;
            this.handleQuery();
            this.selectionChangeType = extendParams.selectionChangeType;
            this.needCheckFriendsParentStaffFlag = extendParams.needCheckFriendsParentStaffFlag;
        },

        ok() {
            if (this.selectionChangeType === 'one') {
                if (this.selectData.length > 1) {
                    this.$message({
                        type: 'info',
                        message: '不可勾选多个!',
                    });
                } else {
                    if (
                        this.needCheckFriendsParentStaffFlag &&
                        !this.$refs['pickUpStaff'].selection[0].isFriendsParentStaff
                    ) {
                        this.$message({
                            type: 'info',
                            message: '所选人员不是分销员',
                        });
                        return;
                    }
                    this.dialogFormVisible = false;
                    this.$emit('ok', this.$refs['pickUpStaff'].selection);
                }
            } else {
                this.dialogFormVisible = false;
                this.$emit('ok', this.$refs['pickUpStaff'].selection);
            }
        },
    },
    filters: {
        roles(roles) {
            return roles ? roles.map((r) => r.name).join(',') : '';
        },
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type == '1') {
                return '业务员工';
            }
            if (type == '0') {
                return '管理员';
            }
        },
        sex(sex) {
            if (typeof sex == 'undefined') {
                return '';
            }
            if (sex == '0') {
                return '男';
            }
            if (sex == '1') {
                return '女';
            }
        },
        isFriendsParentStaff(isFriendsParentStaff) {
            if (typeof isFriendsParentStaff == 'undefined') {
                return '';
            }
            if (isFriendsParentStaff == true) {
                return '是';
            }
            if (isFriendsParentStaff == false) {
                return '否';
            }
        },
    },
};
</script>
